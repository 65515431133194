/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.cdnfonts.com/css/gotham-bold'); //font-family: 'Gotham Bold', sans-serif;
@import url('https://fonts.cdnfonts.com/css/gotham-book'); //font-family: 'Gotham Book', sans-serif;
@import url('https://fonts.cdnfonts.com/css/gotham?styles=17591'); //font-family: 'Gotham Light', sans-serif;
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

// end browser reset


//colors
:root {
	--green: #86bc25;
	--light-blue: #3e4548;
	--dark-blue: #3d4548;
	--grey: #7f7f7f;
	--warm-grey: #f3f1ee;
}

html,
body {
	padding: 0;
	margin: 0;
}

// global headings

.h2 {
	font-family: 'Gotham Bold', sans-serif;
	text-transform: uppercase;
	margin: .5rem;
	font-size: 2rem;
	line-height: 1.5;
	color: var(--dark-blue);
	text-align: center;
}

.contentfulRichText {
	p {
		font-family: "Gotham Light", sans-serif;
		line-height: 1.4;
		font-size: 1.1rem;
		margin-bottom: 1rem;

		b {
			font-weight: bold;
		}

	}

	h1 {
		text-transform: uppercase;
		font-family: 'Gotham Bold', sans-serif;
		font-size: 2rem;
		color: var(--dark-blue);
		margin-bottom: .4rem;
		font-weight: bold;
	}

	h2 {
		text-transform: uppercase;
		font-family: 'Gotham Bold', sans-serif;
		font-size: 1.5rem;
		color: var(--dark-blue);
		margin-bottom: .4rem;
		font-weight: bold;
	}

	h3 {
		text-transform: uppercase;
		font-family: 'Gotham Bold', sans-serif;
		font-size: 1.2rem;
		color: var(--dark-blue);
		margin-bottom: .4rem;
		font-weight: bold;
	}

	h4 {
		text-transform: uppercase;
		font-family: 'Gotham Bold', sans-serif;
		font-size: 1.1rem;
		color: var(--dark-blue);
		margin-bottom: .3rem;
		font-weight: bold;
	}

	h5 {
		text-transform: uppercase;
		font-family: 'Gotham Bold', sans-serif;
		font-size: 1rem;
		color: var(--dark-blue);
		margin-bottom: .3rem;
		font-weight: bold;
	}
	i,
	code {
		font-style: italic;
		text-transform: none;
		color: #86bc24;
		font-family: 'Whitney-Medium', Arial, Helvetica, sans-serif;
		line-height: 1.25;

	}
	.richTextImage,
	.richTextVideo {
		max-width: 70vw;
		display: block;
		margin: 40px auto;
	}

	.richTextVideo {
		max-height: 50vh;
	}
}

//table style
table {
	border: 1px solid #c8c8c8;
	border-radius: 8px;
	width: 100%;
	border-collapse: separate;
	overflow: hidden;

	td,
	th {
		padding: 10px;
		text-align: left;
	}

	tr:nth-of-type(2n) {
		background-color: #f1f1f1;
	}

	th {
		background-color: #178841;
		color: #fff;
		font-family: "FreshSans_Medium", Arial;
	}
}

.greenText {
	color: #86bc24;
}